<template>
  <div>
    <h1>Видимо, что-то пошло не так (((</h1>
  </div>
</template>

<script>
export default {
  name: "PhotographerNotFoundPage",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style scoped>
.body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(@/assets/avatar.jpg);
}
</style>