import { render, staticRenderFns } from "./SliderComp.vue?vue&type=template&id=88e423aa&scoped=true"
import script from "./SliderComp.vue?vue&type=script&lang=js"
export * from "./SliderComp.vue?vue&type=script&lang=js"
import style0 from "./SliderComp.vue?vue&type=style&index=0&id=88e423aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88e423aa",
  null
  
)

export default component.exports