<template>
  <div>
    <ContactsComp />
  </div>
</template>

<script>
import ContactsComp from "@/components/ContactsComp.vue";

export default {
  name: "ContactPage",
  components: {
    ContactsComp,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style scoped>
</style>