<template>
  <div>
    <AboutComp />
  </div>
</template>

<script>
import AboutComp from "@/components/AboutComp.vue";

export default {
  name: "AboutPage",
  components: {
    AboutComp,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style scoped>
</style>